<template>
  <div class="form-component">
    <label for="">
      Inventory
    </label>
    <v-row gutters>
      <v-col cols="6">
        <InputNest size="12px" label="SKU (stock keeping unit)" :model="['variantDefault', 'sku']" />
      </v-col>
      <v-col cols="6">
        <InputNest size="12px" label="Barcode (ISBN, UPC, GTIN, etc.)" :model="['variantDefault', 'barCode']" />
      </v-col>
      <v-col cols="12">
        <label class="fz-12">Inventory policy</label>
        <v-select
          :items="[
            { name: `Lattehub tracks this product's inventory`, value: 'continue' },
            { name: `Don't track inventory`, value: 'deny' },
          ]"
          v-model="$parent.variantDefault.inventoryPolicy"
          item-value="value"
          item-text="name"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
