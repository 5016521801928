<template>
  <div class="form-component">
    <label for="">
      Pricing
    </label>
    <v-row gutters>
      <v-col cols="6">
        <InputNest
          size="12px"
          label="Price"
          :model="['variantDefault', 'price']"
          type="number"
          :suffix="currency.currencyCode()"
        />
      </v-col>
      <v-col cols="6">
        <InputNest
          size="12px"
          label="Compare at price"
          :model="['variantDefault', 'compareAtPrice']"
          type="number"
          :suffix="currency.currencyCode()"
        />
      </v-col>
      <v-col cols="6">
        <InputNest
          size="12px"
          label="Cost per item"
          :model="['variantDefault', 'costPerItem']"
          type="number"
          :suffix="currency.currencyCode()"
        />
      </v-col>
      <v-col cols="6">
        <InputNest
          size="12px"
          label="Base cost"
          :model="['variantDefault', 'baseCost']"
          type="number"
          :suffix="currency.currencyCode()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import currency from '@/helpers/currency';
export default {
  data: function() {
    return {
      currency,
    };
  },
};
</script>

<style lang="scss" scoped></style>
