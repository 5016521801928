<template>
  <div>
    <label class="fz-12">{{ `Select ${name}` }}</label>
    <v-autocomplete
      @change="onchange"
      v-model="selectedValues"
      :items="values"
      item-text="value"
      multiple
      hide-selected
      chips
      small-chips
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
        >
          {{ data.item.value }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    values: Array,
    onSelectedOptions: Function,
    removeOption: Function,
    tags: [],
  },
  data: function() {
    return {
      selectedValues: [],
    };
  },
  created() {
    for (const tag of this.$parent?.attributes?.tags) {
      let found = this.values.find(value => value.value == tag);
      if (found) {
        this.selectedValues.push(tag);
      }
    }
  },
  methods: {
    remove(item) {
      const index = this.selectedValues.indexOf(item);
      if (index >= 0) this.selectedValues.splice(index, 1);
      this.removeOption(this.name, item);
    },
    onchange() {
      this.onSelectedOptions(this.name, this.selectedValues);
    },
  },
  watch: {
    '$parent.attributes.tags': function(tags) {
      for (const value of this.selectedValues) {
        if (!tags.includes(value)) {
          const index = this.selectedValues.indexOf(value);
          // console.log(111111111, index, value );
          if (index >= 0) this.selectedValues.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style></style>
